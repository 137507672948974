<template lang="pug">
include ../mixins
+b.ls-card--space_custom.--color_white.--border_3sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  +b.modal-close--common.BUTTON(
    tabindex="-1"
    @click="$emit('close')"
  )
  template(
    v-if='hasContract'
  )
    +b.P.ls-text.--size_3sm.--weight_semi-bold.--height_md.--variant_center {{ _("Вы уверенны, что хотите оплатить?") }}
    +b.ma.--top_sm
      +b.P.ls-text.--size_2sm.--weight_semi-bold.--height_md.--variant_center.--color_accent-1 {{ _("Сумма к оплате:") }} {{ objectItem.period.paymentAmount }}
    +b.ma.--top_sm(v-if="nonFieldErrors[0]")
      +b.P.control-hint.--variant_1.--appearance_center {{ nonFieldErrors[0] }}
    +b.ma.--top_md
      +b.g-row.--align_center
        +b.g-cell.g-cols--6
          +b.i-link.--color_accent-1(
            @click.prevent="$emit('close')"
          ) {{ _('Отмена') }}
        +b.g-cell.g-cols--6
          +b.BUTTON.ls-button.--variant_accent-1(
            @click.prevent="paymentConfirm"
          ) {{_('Оплатить')}}
  template(
    v-else
  )
    +b.P.ls-text.--size_2sm.--weight_semi-bold.--height_md {{ _("Ознайомлений/на та приймаю (акцепт) пропозицію укласти Публічний договір приєднання (оферта) та погоджуюся з Правилами для студентів.") }}
    +b.ma.--top_sm.txt-center
      +b.A.ls-text.--size_2sm.--weight_semi-bold.--height_md.--variant_center.--color_accent-1(
        :href="contractLink"
        target="_blank"
      ) {{ _("Ознайомитися з договором") }}
    +b.ma.--top_sm.txt-center(v-if="nonFieldErrors[0]")
      +b.P.control-hint.--variant_1.--appearance_center {{ nonFieldErrors[0] }}
    +b.ma.--top_sm
      +b.g-row.--align_center
        +b.g-cell.g-cols--12.txt-center
          +b.form.ma--bottom_sm
            +b.LABEL.d-control-checkbox.--variant_flex-center
              +e.INPUT.element(
                type='checkbox'
                v-model='isAccepted'
                name='isAccepted'
              )
              +e.label
              +e.SPAN.content {{ _('Підтверджую, що прочитав і згоден') }}
        +b.g-cell.g-cols--12.txt-center
          +b.BUTTON.ls-button.--variant_accent-1(
            @click.prevent="contractConfirm"
            :disabled="!isAccepted"
          ) {{_('Погоджуюсь та приймаю')}}
</template>

<script>
import {
  paymentPeriodPayResource,
} from '@/js/services/payment-period.service'
import {
  groupContractCreateResource,
} from '@/js/services/document.service'
import {
  defaultValidatorErrorsParser,
  getCommonParsedErrors,
} from '@/js/mixins/form.mixin.js'

export default {
  props: {
    objectItem: {},
    // hasContract: {},
    // groupId: {},
  },

  data() {
    return {
      formdata: {
        isAccepted: false,
      },
      isLoad: false,
      nonFieldErrors: [],
      hasContract: false,
      groupId: 0,
      contractLink: '',
      isAccepted: false,
    }
  },
  mounted() {
    console.log('mounted', this.objectItem)
    // console.log(this.hasContract)
    // console.log(this.groupId)
    this.hasContract = this.objectItem.hasContract
    this.groupId = this.objectItem.groupId
    this.contractLink = this.objectItem.contractLink
  },
  methods: {
    paymentConfirm() {
      const formdata = {
        amount: this.objectItem.period.paymentAmount,
        payPeriod: this.objectItem.period.id,
      }

      this.isLoad = true

      this.nonFieldErrors = []

      paymentPeriodPayResource.execute({}, formdata).then(res => {
        const { data: { item: { urlPay } } } = res

        window.location = urlPay
      }).catch(async e => {
        const errors = await getCommonParsedErrors(e)

        this.updateValidator(errors)
      }).finally(() => {
        this.isLoad = false
      })
    },

    contractConfirm() {
      const formdata = {
        group: this.groupId,
      }

      this.isLoad = true

      this.nonFieldErrors = []
      console.log('formdata', formdata)
      groupContractCreateResource.execute({}, formdata).then(() => {
        // const { data: { item: { urlPay } } } = res
        this.hasContract = true
        // window.location = urlPay
      }).catch(async e => {
        const errors = await getCommonParsedErrors(e)

        this.updateValidator(errors)
      }).finally(() => {
        this.isLoad = false
      })
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)

      this.nonFieldErrors = parsed.nonFieldErrors
    },
  },
}
</script>
