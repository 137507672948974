import {
  createResource,
  getResource,
  postResource,
  patchResource,
} from '@/js/resource/resource'
import { simpleTransformer } from '@/js/resource/transformer'

import { TESTS_MODULE } from './paths'

export const createConfTestResource = createResource(
  TESTS_MODULE.CREATE_CONFIG, postResource
)
export const updateConfTestResource = createResource(
  TESTS_MODULE.UPDATE_CONFIG, patchResource
)
export const getConfTestResource = createResource(
  TESTS_MODULE.GET_BASE_CONFIG, getResource
)
export const getListOfConfResource = createResource(
  TESTS_MODULE.LIST_OF_CONFIG, getResource
)
export const getListOfConfShortResource = createResource(
  TESTS_MODULE.LIST_OF_CONFIG_SHORT, getResource
)

export const getListOfConfSkillsResource = createResource(
  TESTS_MODULE.LIST_OF_CONF_SKILLS, getResource
)
export const updateSkillsOfTestResource = createResource(
  TESTS_MODULE.SKIILS_OF_CONF_UPDATE, patchResource
)

export const getGradesOfConfSkillsResource = createResource(
  TESTS_MODULE.GRADES_CONF_RETRIVE, getResource
)
export const updateGradesOfTestResource = createResource(
  TESTS_MODULE.GRADES_CONF_UPDATE, patchResource
)

export const deleteConfTestResource = createResource(
  TESTS_MODULE.DELETE_CONFIG, postResource
)
export const validateConfTestResource = createResource(
  TESTS_MODULE.VALIDATE_CONFIG, patchResource
)
export const getListOfConfSkillsForGradeResource = createResource(
  TESTS_MODULE.LIST_OF_CONF_SKILLS_FOR_GRADE, getResource
)

export const createStudentTestResource = createResource(
  TESTS_MODULE.CREATE_TEST, postResource
)
export const getFilteredListOfStudentTestsResource = createResource(
  TESTS_MODULE.TEST_LIST, getResource
)
export const getFiltersOfStudentTestsResource = createResource(
  TESTS_MODULE.TEST_LIST_FILTERS, getResource
)
export const getStudentTestResource = createResource(
  TESTS_MODULE.GET_TEST, getResource
)
export const updateStudentTestResource = createResource(
  TESTS_MODULE.UPDATE_TEST, patchResource
)
export const getListOfStudentGradesForTestResource = createResource(
  TESTS_MODULE.LIST_OF_STUDENT_GRADES, getResource
)
export const updateGradeSkillOfStudentResource = createResource(
  TESTS_MODULE.UPDATE_GRADE_SKILL_OF_STUDENT, patchResource
)
export const updateGradeTestOfStudentResource = createResource(
  TESTS_MODULE.UPDATE_GRADE_TEST_OF_STUDENT, patchResource
)
export const updateTestStatusResource = createResource(
  TESTS_MODULE.UPDATE_TEST_STATUS, patchResource
)
export const updateTestShowResultsResource = createResource(
  TESTS_MODULE.UPDATE_TEST_SHOW_RESULTS, patchResource
)
export const getTypesOfTestResource = createResource(
  TESTS_MODULE.TYPES_OF_TEST, getResource
)

export const hardRebuildStudentTestResource = createResource(
  TESTS_MODULE.HARD_REBUILD_STUDENT_TEST, patchResource
)
export const actualizeGroupForStudentTestResource = createResource(
  TESTS_MODULE.ACTUALIZE_GROUP_FOR_STUDENT_TEST, patchResource
)
export const actualizeGroupForStudentTestAndReturToGradeResource = createResource(
  TESTS_MODULE.ACTUALIZE_GROUP_FOR_STUDENT_AND_RETURN_TO_GRADE_TEST,
  patchResource
)
export const getTestStatusResource = createResource(
  TESTS_MODULE.GET_TEST_STATUS, getResource
)

export const getTestsStatisticsXlsxResource = createResource(
  TESTS_MODULE.GET_TESTS_STATISTICS_XLSX, postResource, simpleTransformer
)

export const ResultsOfStudentTestsResurce = createResource(
  TESTS_MODULE.RESULTS_OF_STUDENT_TESTS, getResource
)
